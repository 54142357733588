<template>
  <Layout>
    <div class="row">
      <div class="col-xl-8 mb-3">
        <VideoDetailsCard v-if="lecture" :videoDetails="lecture" />
      </div>

      <div class="col-xl-4 mb-3">
        <div class="lesson-videos">
          <div class="d-flex align-items-center mb-30 ">
            <div class="w-25">
              <CircleProgressbar
                :progress="detail.total_user_progress"
                progressColor="#3271e6"
                :progressSize="progressSize"
              />
            </div>

            <div class="ml-10">
              <p class="h5 mb-0">
                {{ detail.translator && detail.translator.title }}
              </p>
              <small class="text-gray-soft">Ders Devam Ediyor</small>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-between mb-10">
            <small class="text-gray-soft fw-500">
              Ders Sayısı:
              <span class="text-gray-dark">{{
                getLecturesByType(this.streamType).length
              }}</span>
            </small>

            <small
              class="text-gray-dark fw-500"
              v-if="streamType === 'RECORD'"
              >{{
                moment
                  .utc(getTotalDurationByType(this.streamType) * 1000)
                  .format('HH:mm:ss')
              }}</small
            >
          </div>

          <div
            class="d-flex justify-content-between"
            v-if="detail.total_live_record_lecture_count"
          >
            <button
              class="btn flex-grow-1 rounded-sm"
              v-bind:class="{ 'btn-primary ': streamType === 'LIVE_RECORD' }"
              v-on:click="streamType = 'LIVE_RECORD'"
            >
              Adobe Kayıtları
            </button>
            <button
              class="btn flex-grow-1 rounded-sm"
              v-bind:class="{ 'btn-primary ': streamType === 'RECORD' }"
              v-on:click="streamType = 'RECORD'"
            >
              Düzenlenmiş Kayıtlar
            </button>
          </div>

          <hr class="my-3" />

          <ul class="lesson-videos-line">
            <li
              class="videos-line-item"
              v-bind:class="{
                active:
                  item.id == $route.params.lecture_id &&
                  streamType === 'RECORD',
                watched: isWatched(item),
              }"
              v-for="(item, index) in getLecturesByType(streamType)"
              :key="index" v-on:click.prevent="openLecture(item)"
            >
              <a
                href=""
                class="fw-500 d-block mb-0 mr-15"

              >
                <span style="color:darkgray">{{
                  String(index + 1).padStart(2, '0')
                }}</span>
                <span class=" line-clamp line-clamp-2 hover-display-block ">{{
                  item.translate.title
                }}</span>
              </a>

              <span class="ml-auto" v-if="streamType === 'RECORD'">{{
                moment
                  .utc(getLectureStreamDurationByType(item, streamType) * 1000)
                  .format('HH:mm:ss')
              }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../components/Layout';
import VideoDetailsCard from '../../components/VideoDetailsCard';
import CircleProgressbar from '../../components/CircleProgressbar';
import router from '../../router';
import EventBus from '../../event-bus';

export default {
  data() {
    return {
      progressSize: 50,
      detail: {},
      streamType: 'RECORD',
      lectures: [],
      lecture: {},
    };
  },
  components: { Layout, VideoDetailsCard, CircleProgressbar },
  created() {
    this.fetchLesson();

    EventBus.$on('video_ended', (data) => {
      this.fetchLesson();
    });
  },
  methods: {
    fetchLesson() {
      let lessonId = this.$route.params.id;

      if (!lessonId) {
        router.push({ name: 'lesson_list' });
      }

      this.axios.get('/lesson/' + lessonId).then((response) => {
        this.detail = response.data.data;
        if (this.detail.total_lesson_count) {
          this.fetchLecture();
        }
      });
    },
    fetchLecture() {
      let lessonId = this.$route.params.id;

      this.axios
        .get('/lesson/lecture?lesson_id=' + lessonId)
        .then((response) => {
          this.lectures = response.data.data;

          if (this.lectures.length && !this.$route.params.lecture_id) {
            this.$router.replace({
              name: 'lesson_detail',
              params: { lecture_id: this.lectures[0].id },
            });
          }

          this.findUnWatchedLecture();
        });
    },
    openLecture(lecture) {
      switch (this.streamType) {
        case 'RECORD':
          if (this.$route.params.lecture_id != lecture.id) {
            this.$router.push({
              name: 'lesson_detail',
              params: { lecture_id: lecture.id },
            });
          }
          break;
        case 'LIVE_RECORD':
          lecture.streams.forEach((stream) => {
            if (stream.stream_type === 'LIVE_RECORD') {
              switch (stream.stream_data.api) {
                case 'ADOBE_CONNECT':
                case 'ADOBE_CONNECT_OLD':
                  window.location.href = stream.stream_data.url;
                  break;
              }
            }
          });
          break;
      }
    },
    isWatched(lecture) {
      return lecture.streams.filter((stream) => {
        if (
          stream.stream_type === this.streamType &&
          stream.user_watching_statistic &&
          stream.user_watching_statistic.watching_complete
        ) {
          return true;
        }
      }).length;
    },
    getLecturesByType(type) {
      return this.lectures.filter((lecture) => {
        return lecture.streams.filter((stream) => {
          if (stream.stream_type === type) {
            return true;
          }
        }).length;
      });
    },
    getTotalDurationByType(type) {
      let totalDuration = 0;

      this.lectures.forEach((lecture) => {
        lecture.streams.forEach((item) => {
          if (
            item.stream_type === type &&
            item.stream_data &&
            item.stream_data.duration
          ) {
            totalDuration += item.stream_data.duration;
          }
        });
      });

      return totalDuration;
    },
    getLectureStreamDurationByType(lecture, type) {
      let totalDuration = 0;

      lecture.streams.forEach((item) => {
        if (
          item.stream_type === type &&
          item.stream_data &&
          item.stream_data.duration
        ) {
          totalDuration += item.stream_data.duration;
        }
      });

      return totalDuration;
    },
    findUnWatchedLecture() {
      let lectures = this.getLecturesByType(this.streamType);

      let unwatchedLectures = lectures.filter((lecture) => {
        return lecture.streams.filter((stream) => {
          if (stream.stream_type === this.streamType) {
            if (
              stream.user_watching_statistic &&
              stream.user_watching_statistic.watching_complete
            ) {
              return false;
            }

            return true;
          }
        }).length;
      });

      if (unwatchedLectures.length) {
        this.openLecture(unwatchedLectures[0]);
      }
    },
  },
  watch: {
    '$route.params.lecture_id': {
      handler: function(lecture_id) {
        if (lecture_id) {
          this.axios.get('/lesson/lecture/' + lecture_id).then((response) => {
            this.lecture = response.data.data;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
