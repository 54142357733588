<template>
    <LayoutUser>
        <p class="h5 mb-20">
            Kişisel Bilgiler
        </p>
        <div class="img-uploader-wrap mb-20">
            <label class="img-uploader " :style="profileImgBg">
                <input
                    type="file"
                    @change="onFileSelected"
                    name="file"
                    class="d-block"
                />

                <span class="profile-ico">
          <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2143 2.59494C10.2407 2.63948 10.287 2.6713 10.3466 2.6713C11.9343 2.6713 13.2309 3.91852 13.2309 5.44573V9.22557C13.2309 10.7528 11.9343 12 10.3466 12H2.88434C1.29001 12 0 10.7528 0 9.22557V5.44573C0 3.91852 1.29001 2.6713 2.88434 2.6713C2.93726 2.6713 2.99019 2.64584 3.01003 2.59494L3.04973 2.51858C3.07254 2.47235 3.09595 2.42486 3.11971 2.37666C3.28895 2.03339 3.47618 1.65363 3.59219 1.43044C3.89651 0.857735 4.41251 0.539566 5.05421 0.533203H8.17009C8.81179 0.539566 9.33441 0.857735 9.63873 1.43044C9.74293 1.63091 9.9017 1.95384 10.0547 2.26508C10.0863 2.32926 10.1176 2.39294 10.1481 2.45494L10.2143 2.59494ZM9.73173 5.03785C9.73173 5.35602 9.99635 5.61055 10.3271 5.61055C10.6579 5.61055 10.9291 5.35602 10.9291 5.03785C10.9291 4.71968 10.6579 4.45878 10.3271 4.45878C9.99635 4.45878 9.73173 4.71968 9.73173 5.03785ZM5.47068 6.02344C5.78161 5.72436 6.18515 5.56528 6.61515 5.56528C7.04516 5.56528 7.4487 5.72436 7.75301 6.01707C8.05732 6.30979 8.22271 6.69796 8.22271 7.11157C8.21609 7.96427 7.50163 8.65787 6.61515 8.65787C6.18515 8.65787 5.78161 8.49879 5.47729 8.20607C5.17298 7.91336 5.0076 7.52519 5.0076 7.11157V7.10521C5.00098 6.70432 5.16637 6.31615 5.47068 6.02344ZM8.44733 8.88131C7.97763 9.33311 7.32932 9.6131 6.61485 9.6131C5.92022 9.6131 5.27191 9.3522 4.77575 8.88131C4.2862 8.40405 4.01497 7.78044 4.01497 7.11229C4.00835 6.4505 4.27959 5.82689 4.76913 5.34964C5.26529 4.87238 5.92022 4.61148 6.61485 4.61148C7.30947 4.61148 7.9644 4.87238 8.45394 5.34327C8.94349 5.82053 9.21472 6.4505 9.21472 7.11229C9.20811 7.8059 8.91703 8.42951 8.44733 8.88131Z"
              />
            </g>
          </svg>
        </span>
            </label>

            <!-- <span class="profile-ico delete" @click="removeImage">
              <i class="icon-plus"></i>
            </span> -->
        </div>
        <form v-on:submit.prevent="submitForm">
            <div class="row">
                <div class="col-md-12 mb-25">
                    <div class="account-input-group">
                        <input
                            type="text"
                            placeholder="Email Adresiniz"
                            disabled
                            v-model="form.email"
                        />
                        <label class="text-muted">Email</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <input
                            type="text"
                            placeholder="İsminizi giriniz"
                            disabled
                            v-model="form.firstname"
                        />
                        <label class="text-muted">Adınız</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <input
                            type="text"
                            disabled
                            placeholder="İsminizi giriniz"
                            v-model="form.lastname"
                        />
                        <label class="text-muted">Soyadınız</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <select v-model="form.gender_id">
                            <option value="1">Erkek</option>
                            <option value="2">Kadın</option>
                        </select>

                        <label class="text-muted">Cinsiyet</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <ul class="formulate-input-errors" v-if="!isValid">
                            <li role="status" aria-live="polite" class="formulate-input-error">Telefon numarası hatalı bir formattadır.</li>
                        </ul>
                        <mobile-phone-input :data="form.mobile_phone" @mobile-phone-number="handleMobilPhoneNumber" :disabledData="phoneNum ? true : false"/>
                        <label class="text-muted">Telefon</label>
                    </div>
                </div>

                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <input
                            type="date"
                            placeholder="Telefon numaranızı giriniz"
                            v-model="form.birth_date"
                        />
                        <label class="text-muted">Doğum Tarihi</label>
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-end">
                    <button class="btn btn-primary px-30 ">Kaydet</button>
                </div>
            </div>
        </form>
    </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';
import MobilePhoneInput from "@/components/MobilePhoneSelector.vue";

export default {
    components: {LayoutUser, MobilePhoneInput},
    computed: {
        profileImgBg() {
            if (this.$root.user.avatar != null) {
                return (
                    'background-image:url( ' + this.$root.user.avatar.thumb.small + ')'
                );
            } else {
                if (this.$root.user?.gender_id == 2) {
                    return (
                        'background-image:url(' +
                        this.$root.getWebSiteURL() +
                        '/system/icon/avatar-woman.jpeg)'
                    );
                }
                return (
                    'background-image:url(' +
                    this.$root.getWebSiteURL() +
                    '/system/icon/avatar-man.jpeg)'
                );
            }
        },
    },
    data() {
        return {
            errors: [],
            fileInput: '',
            form: {
                firstname: '',
                lastname: '',
                email: '',
                avatar: '',
                mobile_phone: '',
                birth_date: '',
                gender_id: '',
            },
            image: '',
            selectedFile: '',
            userDetails: {},
            phoneNum: '',
            isValid: true,
        };
    },
    methods: {
        removeImage: function () {
            this.selectedFile = '';
        },
        fetchUserDetails() {
            this.axios.get('/user/check').then((response) => {
                this.form = response.data.session;
                this.phoneNum = response.data.session.mobile_phone;
            });
        },
        submitForm() {
            if (this.isValid) {
                this.axios
                    .put('/user/update', this.form)
                    .then(() => {
                        this.$notify({
                            title:
                                '<div class="d-flex align-items-center"> <i class="icon-check-stroke h4 mb-0 mr-5"></i> Başarılı</div>',
                            type: 'success',
                        });
                    })
                    .catch(() => {
                        this.$notify({
                            title:
                                '<div class="d-flex align-items-center"> <i class="icon-alert h4 mb-0 mr-5"></i> Bir hata oluştu</div>',
                            type: 'error',
                        });
                    })
                    .finally(() => {
                    });
            }
        },
        onFileSelected(event) {
            this.selectedFile = event.target.files[0];
            this.onUpload();
        },
        onUpload() {
            const fd = new FormData();
            fd.append('file', this.selectedFile);
            this.axios
                .post('/user/change-avatar', fd)
                .then(() => {
                    this.$router.go(this.$router.currentRoute);
                })
                .catch(() => {
                });
        },
        handleMobilPhoneNumber(data) {
            this.form.mobile_phone = data.number;
            this.isValid = data.isValid;
        },
    },
    created() {
        this.fetchUserDetails();
    },
};
</script>

<style></style>
