<template>
    <LayoutUser>
        <router-link
            :to="{ name: 'account-addresses' }"
            class="d-flex align-items-center h5 mb-10"
        >
            <span class="icon-chevron-down mr-5 rotate-90"></span>
            Adreslerim
        </router-link>

        <FormulateForm @submit="submitForm">
            <div class="row mt-40">
                <div class="col-md-12">
                    <p class="h5 mb-20">
                        Adres Güncelle
                    </p>
                </div>
                <div class="col-md-12 mb-15">
                    <div class="d-flex align-items-center">
                        <label class="d-flex align-items-center mr-15">
                            <input
                                type="radio"
                                name="invoice_type_id"
                                class="mr-5"
                                value="1"
                                v-model="addressDetails.invoice_type_id"
                            />
                            Bireysel
                        </label>
                        <label class="d-flex align-items-center">
                            <input
                                type="radio"
                                name="invoice_type_id"
                                class="mr-5"
                                value="2"
                                v-model="addressDetails.invoice_type_id"
                            />
                            Kurumsal
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-25">
                    <div class="account-input-group">
                        <FormulateInput
                            id="baslik"
                            type="text"
                            validation="required|min:2"
                            validation-name="Adres başlığı"
                            placeholder="Adres başlığını girin"
                            v-model="addressDetails.title"
                        />
                        <label class="text-muted" for="baslik">Adres Başlığı</label>
                    </div>
                </div>

                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <FormulateInput
                            type="text"
                            validation="required|min:2"
                            validation-name="İsim"
                            placeholder="İsminizi giriniz"
                            v-model="addressDetails.firstname"
                        />
                        <label class="text-muted">Adınız</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <FormulateInput
                            type="text"
                            validation="required|min:2"
                            validation-name="Soyadınız"
                            placeholder="Soyadınızı giriniz"
                            v-model="addressDetails.lastname"
                        />
                        <label class="text-muted">Soyadınız</label>
                    </div>
                </div>
                <div
                    class="col-md-6 mb-15"
                    v-show="addressDetails.invoice_type_id == 2"
                >
                    <div class="account-input-group">
                        <FormulateInput
                            type="text"
                            validation-name="İşletme Adı"
                            placeholder="İşletme adını giriniz"
                            v-model="addressDetails.company_name"
                        />
                        <label class="text-muted">İşletme Adı</label>
                    </div>
                </div>
                <div
                    class="col-md-6 mb-15"
                    v-show="addressDetails.invoice_type_id == 2"
                >
                    <div class="account-input-group">
                        <input
                            type="text"
                            placeholder="Vergi Dairesi"
                            v-model="addressDetails.company_tax_office"
                        />
                        <label class="text-muted">Vergi Dairesi</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <input
                            type="text"
                            placeholder="Adres başlığını girin"
                            v-model="addressDetails.identification_number"
                        />
                        <label class="text-muted">TC Kimlik No:</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                      <ul class="formulate-input-errors" v-if="!isValid"><li role="status" aria-live="polite" class="formulate-input-error">Telefon numarası hatalı bir formattadır.</li></ul>
                      <mobile-phone-input :data="addressDetails.mobile_phone" @mobile-phone-number="handleMobilPhoneNumber"/>
                      <label class="text-muted">Telefon No:</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <select v-model="addressDetails.state_id">
                            <option
                                :value="item.id"
                                v-for="item in countryStates"
                                :key="item.id"
                            >{{ item.name }}
                            </option
                            >
                        </select>
                        <label class="text-muted">İl Seçiniz:</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <select name="" id="" v-model="addressDetails.city_id">
                            <option value="">İlçe Seçiniz</option>
                            <option :value="item.id" v-for="item in cities" :key="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                        <label class="text-muted">İlçe Seçiniz:</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <FormulateInput
                            type="text"
                            validation-name="ZIP Kodu"
                            placeholder="ZIP Kodu"
                            v-model="addressDetails.zip_code"
                        />
                        <label class="text-muted">ZIP Kodu</label>
                    </div>
                </div>
                <div class="col-md-6 mb-25">
                    <div class="account-input-group">
                        <FormulateInput
                            type="text"
                            validation="required|min:2"
                            validation-name="Mahalle"
                            placeholder="Mahalle"
                            v-model="addressDetails.district"
                        />
                        <label class="text-muted">Mahalle</label>
                    </div>
                </div>
                <div class="col-md-12 mb-25">
                    <div class="account-input-group">
                        <textarea
                            type="text"
                            placeholder="İsminizi giriniz"
                            rows="5"
                            v-model="addressDetails.address"
                        ></textarea>
                        <label class="text-muted">Adres Detay:</label>
                    </div>
                </div>
                <div class="col-md-12 mb-25">
                    <div class="d-flex align-items-center">
                        Aktif Adres:
                        <label class="switch">
                            <input
                                type="checkbox"
                                :checked="addressDetails.is_default"
                                v-model="addressDetails.is_default"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-25 d-flex justify-content-end">
                    <button class="btn btn-primary px-30" type="submit">Güncelle</button>
                </div>
            </div>
        </FormulateForm>
    </LayoutUser>
</template>

<script>
import LayoutUser from '../components/LayoutUser.vue';
import MobilePhoneInput from "@/components/MobilePhoneSelector.vue";

export default {
    components: {MobilePhoneInput, LayoutUser},
    data() {
        return {
            countryId: 1,
            countryStates: {},
            cities: {},
            addressDetails: {
                invoice_type_id: 1,
                title: '',
                firstname: '',
                lastname: '',
                identification_number: '',
                mobile_phone: '',
                address: '',
                is_default: '',
                state_id: '',
                city_id: '',
                district: '',
                zip_code: '',
                company_name: '',
                company_tax_office: '',
            },
          isValid : true,
        };
    },
    methods: {
        fetchAddressDetails() {
            this.axios
                .get('/user/address/' + this.$route.params.id)
                .then((response) => {
                    this.addressDetails = response.data.data;
                });
        },
        submitForm() {
          if( this.isValid ) {
            -this.axios
              .put(`/user/address/${this.$route.params.id}`, this.addressDetails)
              .then(() => {
                this.$notify({
                  title:
                    '<div class="d-flex align-items-center"> <i class="icon-check-stroke h4 mb-0 mr-5"></i> Başarılı</div>',
                  type: 'success',
                });
                this.$router.push({name: 'account-addresses'});
              });
          }
        },
        handleMobilPhoneNumber(data) {
          this.addressDetails.mobile_phone = data.number;
          this.isValid = data.isValid;
        },
        fetchActiveCountryStates() {
            this.axios
                .get(`/localization/country/state/${this.countryId}`)
                .then((response) => {
                    this.countryStates = response.data.data.states;
                });
        },
        fetchActiveCities() {
            this.axios
                .get(`/localization/country/city/${this.addressDetails.state_id}`)
                .then((response) => {
                    this.cities = response.data.data.cities;
                });
        },
    },
    created() {
        this.fetchAddressDetails();
        this.fetchActiveCountryStates();
    },
    watch: {
        'addressDetails.state_id': {
            handler: function () {
                this.fetchActiveCities();
            },
            deep: true,
        },
        'addressDetails.invoice_type_id': {
            handler: function () {
                if (this.addressDetails.invoice_type_id == 1) {
                    this.addressDetails.company_name = '';
                    this.addressDetails.company_tax_office = '';
                }
            },
            deep: true,
        },
    },
};
</script>

<style></style>
