<template>
    <input
        ref="mobilePhoneInput"
        id="mobile_phone_selector"
        type="text"
        name="mobile_phone"
        inputmode="tel"
        autocomplete="nope"
        v-model="mobilePhone"
        required
        @input="handleInputChange"
        @focus="focus = true"
        :disabled="disabledData"
    >
</template>

<script>
import {PhoneNumberUtil} from "google-libphonenumber";

export default {
    props: {
        data: String,
        disabledData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mobilePhone: '',
            iti: null,
            disableChangeEvent: false,
            focus: false
        };
    },
    mounted() {
        this.initIntlTelInput();
    },
    methods: {
        initIntlTelInput() {
            const input = this.$refs.mobilePhoneInput;
            let countryCode = this.getRegion(this.data) ?? 'TR';

            this.iti = window.intlTelInput(input, {
                initialCountry: countryCode,
                autoPlaceholder: "aggressive",
                separateDialCode: true,
                loadUtils: () => import("@/assets/utils.js"),
                i18n: {
                    tr: 'Türkiye (Turkey)',
                    searchPlaceholder: 'Ülke ara..',
                },
                formatOnDisplay: true
            });

            if (this.data) {
                this.setIntlNumber();
            }

            input.addEventListener('countrychange', () => {
                this.handleCountryChange();
            });
        },
        setIntlNumber(number) {
            if (number) {
                this.iti.setNumber(this.mobilePhone = number);
            }
        },
        handleCountryChange() {
            const input = this.$refs.mobilePhoneInput;
            const countryDialCode = this.iti.getSelectedCountryData().dialCode;
            const countryContainerWidth = input.closest('.iti').querySelector('.iti__selected-country').offsetWidth;
            input.style.setProperty('--dynamic-padding', `${countryContainerWidth + 16}px`);
            input.classList.add('dynamic-padding');

            this.$emit("mobile-phone-number", {
                number: '+' + countryDialCode,
                isValid: false
            });
        },
        handleInputChange() {
            const input = this.$refs.mobilePhoneInput;
            const countryDialCode = this.iti.getSelectedCountryData().dialCode;
            const countryCode = this.iti.getSelectedCountryData().iso2.toUpperCase();
            let inputValue = input.value.replace(/\D/g, "");

            if (inputValue.startsWith(countryDialCode)) {
                inputValue = inputValue.replace(new RegExp(`^${countryDialCode}`), "");
            }

            if (!input.placeholder) {
                return false;
            }

            let index = 0;
            this.mobilePhone = input.placeholder.replace(/[0-9]/g, '#').replace(/#/g, () => inputValue[index++] || "").trim();

            this.$emit("mobile-phone-number", {
                number: '+' + countryDialCode + this.mobilePhone.replace(/\D/g, ""),
                isValid: this.validatePhoneNumber(this.mobilePhone.replace(/\D/g, ""), countryCode)
            });

            setTimeout(() => {
                input.value = this.mobilePhone;
            });
        },
        getRegion(number) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const parsedNumber = phoneUtil.parse(number);
                return phoneUtil.getRegionCodeForNumber(parsedNumber) ?? null;
            } catch (e) {
                return null;
            }
        },
        validatePhoneNumber(number, country) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const phone = phoneUtil.parseAndKeepRawInput(number, country);
                return phone && phoneUtil.isValidNumber(phone) && phoneUtil.getNumberType(phone) === 1;
            } catch (e) {
                return false;
            }
        },
    },
    watch: {
        data(newVal) {
            if (newVal) {
                this.setIntlNumber(newVal);
            }
        }
    }
};
</script>

<style>
.iti__country-container .iti__selected-country {
    background-color: #f3f3f4;
    border-radius: 8px;
    padding-right: 4px;
}

.iti__selected-country-primary {
    padding-right: 0;
}

.iti__country-container .iti__selected-country .iti__selected-dial-code {
    font-size: 12px;
}

.iti--inline-dropdown .iti__dropdown-content {
    margin-top: 8px;
    border-radius: 16px;
    border: 1px solid #f3f3f4;
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
    background: transparent;
}

.iti__search-input {
    padding: 16px 8px;
}

body[data-theme="dark"] .iti__country-container .iti__selected-country {
    background-color: #151f2f;
}

body[data-theme="dark"] .iti__dropdown-content {
    background-color: #151f2f;
    border: 1px solid #293342;
}
</style>
