<template>
    <div class="mb-5">
        <div class="lesson-sectionss">
            <details :open="showChildren">
                <summary class="d-flex justify-content-between" @click="toggleChildren" @keypress="toggleChildren">
                    <div>
                        {{ node.translate.title }}

                        <small class="d-block text-gray-soft" v-if="hasLecture && streamType == 'RECORD'">
                            {{
                                Math.floor(
                                    moment
                                        .duration(calculateFolderDuration(node), 'seconds')
                                        .asHours(),
                                ) +
                                ' saat ' +
                                moment
                                    .duration(calculateFolderDuration(node), 'seconds')
                                    .minutes() +
                                ' dk'
                            }}
                        </small>
                    </div>

                    <i class="icon-chevron-down mr-10" v-if="hasChildren || hasLecture" :class="toggleChildrenIcon" />
                </summary>

                <div class="details-content">
                    <ul class="lesson-videos-line">
                        <li
                            class="videos-line-item"
                            v-for="(lecture, index) of node.lectures"
                            :key="'lect' + index"
                            v-bind:class="{
                active:
                  lecture.id == $route.params.lecture_id &&
                  streamType === 'RECORD',
                watched: isWatched(lecture),
              }"
                            v-on:click.prevent="openLecture(lecture)"
                        >
                            <div class="fw-500 d-block mb-0 mr-15">
                <span style="color:darkgray">
                  {{ String(index + 1).padStart(2, '0') }}
                </span>

                                <span class=" line-clamp line-clamp-2 hover-display-block ">
                  {{ lecture.translate.title }}
                </span>
                            </div>
                        </li>
                    </ul>

                    <TreeNode
                        v-for="(child, index) in node.children"
                        :key="'asdad' + index"
                        :node="child"
                        :spacing="spacing + 10"
                        :stream-type="streamType"
                    />
                </div>
            </details>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TreeNode',
    props: {
        node: {
            type: Object,
            required: true,
        },
        spacing: {
            type: Number,
            default: 0,
        },
        streamType: {
            type: String,
            default: 'RECORD',
            required: true,
        },
    },
    data() {
        return {
            showChildren: true,
        };
    },
    computed: {
        nodeMargin() {
            return {
                'margin-left': `${this.spacing}px`,
            };
        },
        hasChildren() {
            const {children} = this.node;
            return children && children.length > 0;
        },
        hasLecture() {
            const {lectures} = this.node;
            return lectures && lectures.length > 0;
        },
        toggleChildrenIcon() {
            return {transform: `rotate(180deg)`};
        },
    },
    mounted() {
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        calculateFolderDuration(node) {
            let total = 0;
            node.lectures.map((fold) => {
                fold.streams.map((stream) => {
                    if (stream.stream_data.duration) {
                        total += stream.stream_data.duration;
                    }
                });
            });
            return total;
        },
        isWatched(lecture) {
            return lecture.streams.filter((stream) => {
                if (
                    stream.stream_type === this.streamType &&
                    stream.user_watching_statistic &&
                    stream.user_watching_statistic.watching_complete
                ) {
                    return true;
                }
            }).length;
        },
        openLecture(lecture) {
            switch (this.streamType) {
                case 'RECORD':
                    if (this.$route.params.lecture_id != lecture.id) {
                        this.$router.push({
                            name: 'lesson_detail',
                            params: {lecture_id: lecture.id},
                        });
                    }
                    break;
                case 'LIVE_RECORD':
                    lecture.streams.forEach((stream) => {
                        if (stream.stream_type === 'LIVE_RECORD') {
                            switch (stream.stream_data.api) {
                                case 'ADOBE_CONNECT':
                                case 'ADOBE_CONNECT_OLD':
                                    window.location.href = stream.stream_data.url;
                                    break;
                            }
                        }
                    });
                    break;
            }
        },
    },
};
</script>
