<template>
    <Layout>
        <div class="row">
            <div class="col-xl-8 mb-3">
                <VideoDetailsCard v-if="lecture && plyrStreamDataLoaded" :videoDetails="lecture" :lecture="lecture" :plyrStreamData="plyrStreamData" :plyrStreamDataLoaded="plyrStreamDataLoaded"/>

                <!-- <VideoPlayer
                  :options="videoOptions"
                  v-if="
                    videoOptions.loaded &&
                      lecture.streams &&
                      lecture.streams[0] &&
                      lecture.streams[0].stream_data.api === 'VISTREAM'
                  "
                ></VideoPlayer> -->
            </div>

            <div class="col-xl-4 mb-3">
                <div class="lesson-videos">
                    <div class="d-flex align-items-center mb-30 ">
                        <div class="w-25">
                            <CircleProgressbar :progress="detail.total_user_progress" progressColor="#3271e6" :progressSize="progressSize"/>
                        </div>

                        <div class="ml-10">
                            <p class="h5 mb-0">
                                {{ detail.translator && detail.translator.title }}
                            </p>

                            <small class="text-gray-soft">Ders Devam Ediyor</small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mb-10">
                        <small class="text-gray-soft fw-500">
                            Ders Sayısı:
                            <span class="text-gray-dark">{{ countable.lecture }}</span>
                        </small>

                        <small class="text-gray-dark fw-500" v-if="streamType === 'RECORD'">
                            {{Math.floor(moment.duration(this.countable.totalDuration, 'seconds').asHours()) + ' saat ' + moment.duration(this.countable.totalDuration, 'seconds').minutes() + ' dk'}}
                        </small>
                    </div>

                    <div class="d-flex justify-content-between" v-if="detail.total_live_record_lecture_count">
                        <button class="btn flex-grow-1 rounded-sm" v-bind:class="{ 'btn-primary ': streamType === 'LIVE_RECORD' }" v-on:click="streamType = 'LIVE_RECORD'">
                            Adobe Kayıtları
                        </button>

                        <button class="btn flex-grow-1 rounded-sm" v-bind:class="{ 'btn-primary ': streamType === 'RECORD' }" v-on:click="streamType = 'RECORD'">
                            Düzenlenmiş Kayıtlar
                        </button>
                    </div>

                    <hr class="my-5"/>

                    <div class="lesson-sections">
                        <TreeNode v-for="node in nodes" :key="node.id" :node="node" :streamType="streamType"/>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from '../../components/Layout';
import VideoDetailsCard from '../../components/VideoDetailsCard';
import CircleProgressbar from '../../components/CircleProgressbar';
import router from '../../router';
import EventBus from '../../event-bus';
import TreeNode from '@/components/TreeNode';
import axios from 'axios';
import hotkeys from 'videojs-hotkeys';

export default {
    data() {
        return {
            progressSize: 50,
            detail: {},
            streamType: 'RECORD',
            lectures: [],
            lecture: {},
            lessonFolders: [],
            temp: [],
            videoUrl: '',
            countable: {
                lecture: 0,
                totalDuration: 0,
            },
            plyrStreamData: {},
            videoOptions: {
                autoplay: true,
                controls: true,
                playbackRates: [0.5, 1, 1.5, 2],
                fluid: true,
                sources: [],
                loaded: false,
                plugins: {
                    hotkeys: {
                        enableModifiersForNumber: false,
                        seekStep: 10,
                    },
                },
                poster: '',
            },
            plyrStreamDataLoaded: false,
        };
    },
    components: {
        Layout,
        VideoDetailsCard,
        CircleProgressbar,
        TreeNode,
    },
    created() {
        this.fetchLesson(!this.$route.params.lecture_id);

        EventBus.$on('video_ended', (data) => {
            this.fetchLesson(true);
        });
    },
    computed: {
        nodes() {
            return this.parseLecture(this.lessonFolders, this.streamType);
        },
    },
    methods: {
        fetchLesson(passNextVideo = false) {
            let lessonId = this.$route.params.id;

            if (!lessonId) {
                router.push({name: 'lesson_list'});
            }

            this.axios.get('/lesson/' + lessonId).then((response) => {
                this.detail = response.data.data;

                if (this.detail.total_lesson_count) {
                    this.fetchLecture(passNextVideo);
                }
            });
        },

        fetchLecture(passNextVideo) {
            let lessonId = this.$route.params.id;
            this.axios
                .get(`/lesson/lecture/directory?lesson_id=` + lessonId)
                .then((response) => {
                    this.lessonFolders = response.data.data;
                    if ((!this.$route.params.lecture_id) && !passNextVideo) {
                        let findLecture = this.findFirstLecture();

                        if (findLecture && findLecture.id) {
                            this.$router.replace({
                                name: 'lesson_detail',
                                params: {lecture_id: findLecture.id},
                            });
                        }
                    }

                    if(passNextVideo) {
                        this.$nextTick(() => {
                            this.lectures = [];
                            this.prepareLectureData(this.nodes);
                            this.findUnWatchedLecture();
                        })
                    }
                });
        },

        parseLecture(directories, type = 'xx') {
            let fn;
            this.countable.lecture = 0;
            this.countable.totalDuration = 0;
            directories = JSON.parse(JSON.stringify(directories));

            return directories.filter(
                (fn = (directory) => {
                    directory.lectures = directory.lectures.filter((lecture) => {
                        if (lecture.stream_types.includes(type)) {
                            this.countable.lecture++;

                            lecture.streams.forEach((item) => {
                                if (item.stream_data && item.stream_data.duration) {

                                    this.countable.totalDuration += item.stream_data.duration;
                                }
                            });

                            return true;
                        }
                    });

                    if (directory.lectures.length > 0) {
                        directory.children = directory.children.filter(fn);
                        return true;
                    }

                    return (directory.children = directory.children.filter(fn)).length;
                }),
            );
        },

        calculateFolderDuration(folder) {
            let total = 0;

            folder.lectures.map((fold) => {
                fold.streams.map((stream) => {
                    if (stream.stream_data.duration) {
                        total += stream.stream_data.duration;
                    }
                });
            });

            return total;
        },

        checkActiveFolder(folder) {
            let activeFolderId = '';

            for (let lect of folder.lectures) {
                if (lect.id == this.lecture.id) {
                    activeFolderId = folder.id;
                }
            }

            return activeFolderId;
        },

        openLecture(lecture) {
            switch (this.streamType) {
                case 'RECORD':
                    if (this.$route.params.lecture_id != lecture.id) {
                        this.$router.push({
                            name: 'lesson_detail',
                            params: {lecture_id: lecture.id},
                        });
                    }
                    break;
                case 'LIVE_RECORD':
                    lecture.streams.forEach((stream) => {
                        if (stream.stream_type === 'LIVE_RECORD') {
                            switch (stream.stream_data.api) {
                                case 'ADOBE_CONNECT':
                                case 'ADOBE_CONNECT_OLD':
                                    window.location.href = stream.stream_data.url;
                                    break;
                            }
                        }
                    });
                    break;
            }
        },
        isWatched(lecture) {
            return lecture.streams.filter((stream) => {
                if (
                    stream.stream_type === this.streamType &&
                    stream.user_watching_statistic &&
                    stream.user_watching_statistic.watching_complete
                ) {
                    return true;
                }
            }).length;
        },
        getLecturesByType(type) {
            return this.lectures.filter((lecture) => {
                return lecture.streams.filter((stream) => {
                    if (stream.stream_type === type) {
                        return true;
                    }
                }).length;
            });
        },
        getTotalDurationByType(type) {
            let totalDuration = 0;
            this.lectures.forEach((lecture) => {
                lecture.streams.forEach((item) => {
                    if (
                        item.stream_type === type &&
                        item.stream_data &&
                        item.stream_data.duration
                    ) {
                        totalDuration += item.stream_data.duration;
                    }
                });
            });

            return totalDuration;
        },
        getLectureStreamDurationByType(lecture, type) {
            let totalDuration = 0;

            lecture.streams.forEach((item) => {
                if (
                    item.stream_type === type &&
                    item.stream_data &&
                    item.stream_data.duration
                ) {
                    totalDuration += item.stream_data.duration;
                }
            });

            return totalDuration;
        },

        findFirstLecture(directories = this.lessonFolders) {
            let x = null;

            directories.forEach((directory) => {
                if (directory.lectures && !x) {
                    let find = directory.lectures.filter((lecture) => {
                        return lecture.stream_types.includes('RECORD');
                    });

                    x = find.length ? find[0] : null;
                }

                if (directory.children && !x) {
                    x = this.findFirstLecture(directory.children);
                }
            });

            return x;
        },

        findUnWatchedLecture() {
            let lectures = this.getLecturesByType(this.streamType);

            let unwatchedLectures = lectures.filter((lecture) => {
                return lecture.streams.filter((stream) => {
                    if (stream.stream_type === this.streamType) {
                        if (
                            stream.user_watching_statistic &&
                            stream.user_watching_statistic.watching_complete
                        ) {
                            return false;
                        }

                        return true;
                    }
                }).length;
            });

            if (unwatchedLectures.length) {
                this.openLecture(unwatchedLectures[0]);
            }
        },
        prepareLectureData(lectures){
            lectures.forEach((data) => {
                this.lectures = this.lectures.concat(data.lectures);

                if(data.children && data.children.length > 0) {
                    this.prepareLectureData(data.children);
                }
            });
        }
    },

    watch: {
        '$route.params.lecture_id': {
            handler: function (lecture_id) {
                this.plyrStreamDataLoaded = false;

                if (lecture_id) {
                    this.axios.get('/lesson/lecture/' + lecture_id).then((response) => {
                        this.lecture = response.data.data;
                        let streamData = response.data.data.streams[0].stream_data;

                        this.axios.post('/stream/provider/get-video-detail', {provider_id: streamData.provider_id, account_id: streamData.account_id, video_id: streamData.video_id}).then((response) => {
                            if (response.data.status) {
                                axios.get(response.data.data.public_url).then((response) => {
                                    this.plyrStreamDataLoaded = true;
                                    this.plyrStreamData = response.data.data;
                                });
                            }
                        });

                        //https://cdn-1.dns-stream.com

                        /*axios.get(`https://vn25311.dns-virtara.com/api/v1/public/video/${response.data.data.streams[0].stream_data.video_id}?client_secret=221ae15bfa7fef9b807b788f94d397f17fc743c5`,).then((response) => {
                            this.plyrStreamDataLoaded = true;
                            this.plyrStreamData = response.data.data;
                        });*/
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
